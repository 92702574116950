<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">查看员工</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="search" label-position='left' ref="seachForm"  size="large">
                    <el-form-item style="margin-left:20px;">
						<el-input  :maxlength="50" style="width:300px;" v-model="username" placeholder="请输入员工姓名" clearable></el-input>
					</el-form-item>
                    <el-form-item style="margin-left:20px;">
						<el-input  :maxlength="11" style="width:300px;" v-model="phone" placeholder="请输入手机号" clearable></el-input>
					</el-form-item>
					<el-form-item style="margin-left:20px;">
						<el-button  type="primary" @click="seachSumbit()">查询</el-button>
					</el-form-item>
				</el-form>
			</div>
            <div>
                <el-button  type="primary" @click="$router.back(-1)">返回</el-button>
            </div>
		</div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column prop="username" label="员工姓名" ></el-table-column>
				<el-table-column prop="phone" label="手机号" ></el-table-column>
				<el-table-column prop="jobNumber" label="工号" ></el-table-column>
				<el-table-column prop="email" label="邮箱" ></el-table-column>
				<el-table-column prop="integralBalance" label="积分余额" ></el-table-column>
				<el-table-column prop="rechargeAmount" label="累计充值金额" ></el-table-column>
				<el-table-column prop="consumeAmount" label="累计消费积分" ></el-table-column>
				<el-table-column prop="createTime" label="注册时间" >
					<template slot-scope="scope">
						<span>{{scope.row.createTime != null ? scope.row.createTime : '-'}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="loginTime" label="最近登录时间" >
					<template slot-scope="scope">
						<span>{{scope.row.loginTime != null ? scope.row.loginTime : '-'}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="consumeTime" label="最近消费时间" >
					<template slot-scope="scope">
						<span>{{scope.row.consumeTime != null ? scope.row.consumeTime : '-'}}</span>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		enterprisememberListLow
	} from "@/api/platform/index";
	export default {
		name: "enterprisememberListLow",
		data() {
			return {
                username: '',
                phone:'',
				data: [],
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
                id:''
			};
		},
		mounted() {
			let that = this;
            that.id = this.$route.params.parentId;
			that.list();
		},
		created() {
		},
		methods: {
			//获取列表
			list() {
				enterprisememberListLow({
					page: this.page,
					size: this.pageSize,
                    id:this.id,
                    username:this.username,
                    phone:this.phone
				}).then(res => {
					this.data = res.data.records;
					this.total = res.data.total;
					this.currentPage = res.data.current;
					this.pageCount = res.data.pages;
					if (res.data.current > res.data.pages && res.data.pages != 0) {
						this.page = res.data.pages;
						this.list();
					}
				})
			},
            
            //搜索提交
			seachSumbit(formName){
				this.page = 1
				this.list();
			},

			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.list();
			},

		}
	}
</script>

<style scoped>
	.table-content {margin-top: 20px;}
	.active {color: #049EC4;}
	.form_box{padding:0 50px;}
	.avatar{width:150px;}
	.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
	.flex{display:flex;align-items:center;justify-content:space-between;}

	.meunTop{display:flex;align-items:center;margin-bottom:20px;}
	.meunLine{width:3px;height:20px;background-color:#FFA218;}
	.meunTitle{font-size:20px;margin-left:10px;}
    .el-input__inner{
        -webkit-appearance: none !important;
        -moz-appearance: textfield !important;
    }
    .search .el-form-item{
        margin-bottom: 0;
    }
</style>
